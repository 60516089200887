var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useState } from "react";
import React from "react";
import Cookies from "js-cookie";
import sortArray from "sort-array";
var MembersContext = createContext();
export var MembersProvider = function (_a) {
    var _b;
    var children = _a.children;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var _c = __read(React.useState(0), 2), randQuery = _c[0], setRandQuery = _c[1];
    var _d = __read(useState("carte"), 2), view = _d[0], setView = _d[1];
    var _e = __read(useState(false), 2), companiesIsLoading = _e[0], setCompaniesIsLoading = _e[1];
    var _f = __read(useState(false), 2), filtersIsLoading = _f[0], setFiltersIsLoading = _f[1];
    var _g = __read(useState([]), 2), companies = _g[0], setCompanies = _g[1];
    var _h = __read(useState({}), 2), rowsSearchData = _h[0], setRowsSearchData = _h[1];
    var _j = __read(useState(false), 2), advSearch = _j[0], setAdvSearch = _j[1];
    //Search State
    var _k = __read(useState(""), 2), searchInput = _k[0], setSearchInput = _k[1];
    var _l = __read(useState(""), 2), searchedTerm = _l[0], setSearchedTerm = _l[1];
    //Sector State
    var _m = __read(useState([]), 2), selectedSector = _m[0], setSelectedSector = _m[1];
    var _o = __read(useState([]), 2), sectores = _o[0], setSectors = _o[1];
    //Secondary sector state
    var _p = __read(useState([]), 2), selectedSecSector = _p[0], setSelectedSecSector = _p[1];
    var _q = __read(useState([]), 2), secSectors = _q[0], setSecSectors = _q[1];
    //Type member State
    var _r = __read(useState([]), 2), typeMembre = _r[0], setTypeMembre = _r[1];
    var _s = __read(useState([]), 2), isMember = _s[0], setIsMember = _s[1];
    var _t = __read(useState([]), 2), isMemberValues = _t[0], setIsMemberValues = _t[1];
    var _u = __read(useState([]), 2), companyType = _u[0], setCompanyType = _u[1];
    var _v = __read(useState([]), 2), selectedcompanyCategories = _v[0], setSelectedCompanyCategories = _v[1];
    //Mrc Stat
    var _w = __read(useState([]), 2), mrc = _w[0], setMrc = _w[1];
    var _x = __read(useState([]), 2), mrcNorth = _x[0], setMrcNorth = _x[1];
    var _y = __read(useState([]), 2), selectedMrc = _y[0], setSelectedMrc = _y[1];
    var _z = __read(useState([]), 2), selectedMrcNorth = _z[0], setSelectedMrcNorth = _z[1];
    //Region stat
    var _0 = __read(useState([]), 2), region = _0[0], setRegion = _0[1];
    var _1 = __read(useState([]), 2), regionNorth = _1[0], setRegionNorth = _1[1];
    var _2 = __read(useState([]), 2), selectedRegion = _2[0], setSelectedRegion = _2[1];
    var _3 = __read(useState([]), 2), selectedRegionNorth = _3[0], setSelectedRegionNorth = _3[1];
    //Pagination State
    var _4 = __read(React.useState(0), 2), page = _4[0], setPage = _4[1];
    var _5 = __read(useState(1), 2), pageCount = _5[0], setPageCount = _5[1];
    //query
    var _6 = __read(useState([
        {
            order: 100000001,
            condition: "and",
            field: "is_member",
            operator: "is",
            value: true,
            label: "Oui",
        },
        {
            order: 100000002,
            condition: "and",
            field: "status",
            operator: "is",
            value: ["actived", "imported"],
        }
    ]), 2), rows = _6[0], setRows = _6[1];
    //companies Count
    var _7 = __read(React.useState(0), 2), count = _7[0], setCount = _7[1];
    // highest order
    function getHighestOrder(array) {
        var highestPrice = 99999999;
        for (var i = 0; i < array.length; i++) {
            if (array[i].order > highestPrice) {
                highestPrice = array[i].order;
            }
        }
        return highestPrice;
    }
    // Handle page change
    var handlePageClick = function (e) {
        setPage(e.selected);
        window.scrollTo(0, 0);
    };
    var _8 = __read(useState(true), 2), initialFetch = _8[0], setInitialFetch = _8[1];
    // On the compont mount set the default company type from url
    useEffect(function () {
        if (initialFetch) {
            var currentUrl = new URL(window.location.href);
            var pathname = currentUrl.pathname;
            var pathSegments = pathname
                .split("/")
                .filter(function (segment) { return segment !== ""; });
            var companyFoundType = pathSegments[pathSegments.length - 1];
            var defaultCompanyTypes = {
                providers: "supplier",
                stakeholders: "intervenant",
                contractors: "contractor",
                //need french part also!
                fournisseurs: "supplier",
                intervenants: "intervenant",
                contracteurs: "contractor",
            };
            // Set default company category
            if (defaultCompanyTypes[companyFoundType]) {
                handleTypeMembreChnage({
                    value: defaultCompanyTypes[companyFoundType],
                });
            }
            else {
                // We need that else to show all companies if no default company type is set
                var searchData = {
                    query: searchInput !== searchedTerm ? searchInput : searchedTerm,
                    page: page + 1,
                    filters: [
                        {
                            Company: { conditions: rows },
                        },
                    ],
                };
                fetchCompanies(searchData);
            }
            setInitialFetch(false);
        }
    }, []);
    useEffect(function () {
        //order by first selected filter
        if (!initialFetch) {
            sortArray(rows, {
                by: ["order"],
            });
            // First, check if the first element is not "where"
            if (rows.length > 0 && rows[0].condition !== "where") {
                if (rows[0].field === "code_scian_primaire.code" || rows[0].field === "code_scian_secondaire.code") {
                    rows[0].condition = "or";
                }
                else {
                    rows[0].condition = "where";
                }
            }
            for (var i = 1; i < rows.length; i++) {
                if (rows[i].field !== rows[i - 1].field
                    && rows[i].field !== "code_scian_primaire.code"
                    && rows[i].field !== "code_scian_secondaire.code"
                    && rows[i].field !== "adresses.mrc.id"
                    && rows[i].field !== "autres_address.mrc.id"
                    && rows[i].field !== "adresses.region_adminsitrative.id"
                    && rows[i].field !== "autres_address.region_adminsitrative.id") {
                    rows[i].condition = "and";
                }
                if (rows[i].field == 'code_scian_primaire.code' || rows[i].field == 'code_scian_secondaire.code') {
                    rows[i].group = "scian";
                }
                if (rows[i].field == 'adresses.region_adminsitrative.id' || rows[i].field == 'autres_address.region_adminsitrative.id') {
                    rows[i].group = "region";
                }
            }
            var searchData = {
                query: searchInput !== searchedTerm ? searchInput : searchedTerm,
                page: page + 1,
                filters: [
                    {
                        Company: { conditions: rows },
                    },
                ],
            };
            setRowsSearchData({ conditions: rows });
            if (!advSearch)
                fetchCompanies(searchData);
        }
    }, [rows, page, searchedTerm, randQuery]);
    //fetch Companies
    var fetchCompanies = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        function postData(url, data) {
            if (url === void 0) { url = "/".concat(langCode, "/api/search/"); }
            if (data === void 0) { data = {}; }
            return __awaiter(this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetch(url, {
                                method: "POST",
                                mode: "cors",
                                cache: "no-cache",
                                credentials: "same-origin",
                                headers: {
                                    "X-CSRFToken": CSRF_TOKEN,
                                    "Content-Type": "application/json",
                                    // 'Content-Type': 'application/x-www-form-urlencoded',
                                },
                                redirect: "follow",
                                referrerPolicy: "no-referrer",
                                body: JSON.stringify(data), // body data type must match "Content-Type" header
                            })];
                        case 1:
                            response = _a.sent();
                            return [2 /*return*/, response.json()];
                    }
                });
            });
        }
        var CSRF_TOKEN;
        return __generator(this, function (_a) {
            setCompaniesIsLoading(true);
            CSRF_TOKEN = Cookies.get("csrftoken");
            postData("/".concat(langCode, "/api/search/"), data).then(function (data) {
                var nbrPages = data.count / 10;
                setPageCount(Math.ceil(nbrPages));
                var companies = data.results.map(function (data) {
                    return data.result;
                });
                setCount(data.count);
                setCompanies(companies);
                setCompaniesIsLoading(false);
            });
            return [2 /*return*/];
        });
    }); };
    React.useEffect(function () {
        fetchField();
    }, []);
    //fetch fields
    var fetchField = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, sectores, secSectores, companyType, isMemberChoices;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setFiltersIsLoading(true);
                    return [4 /*yield*/, fetch("/".concat(langCode, "/api/search/fields/?table=Company&context=filters&no_fetching"))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    sectores = data.filter(function (obj) { return obj.field_name == "code_scian_primaire"; });
                    secSectores = data.filter(function (obj) { return obj.field_name == "code_scian_secondaire"; });
                    companyType = data.filter(function (obj) { return obj.field_name == "type_company"; });
                    isMemberChoices = data.filter(function (obj) { return obj.field_name == "is_member"; });
                    //region_adminsitrative
                    if (sectores[0]) {
                        setSectors(sectores[0].values);
                    }
                    else {
                        setSectors([]);
                    }
                    if (secSectores[0]) {
                        setSecSectors(secSectores[0].values);
                    }
                    else {
                        setSecSectors([]);
                    }
                    setCompanyType(companyType[0].choices_labeled);
                    setIsMemberValues(isMemberChoices[0].values);
                    setFiltersIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    //Handle sectore Change
    var handleSelectedSectore = function (item) {
        console.log('Primary sector');
        setPage(0);
        //get latest selected
        var latestSelected = selectedSector
            .filter(function (x) { return !item.includes(x); })
            .concat(item.filter(function (x) { return !selectedSector.includes(x); }));
        var newSector = {
            order: 99999999,
            condition: "or",
            field: "code_scian_primaire.code",
            operator: "istartswith",
            value: latestSelected[0].value,
        };
        if (item.length > selectedSector.length) {
            setSelectedSector(item);
            setRows(function (prevStat) {
                return __spreadArray(__spreadArray([], __read(prevStat), false), [newSector], false);
            });
            if (rows.some(function (obj) {
                return Object.values(obj).includes("code_scian_primaire.code");
            })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === "code_scian_primaire.code"; });
                newSector.order = rows[indx].order;
            }
            else {
                var bigOrder = getHighestOrder(rows);
                newSector.order = bigOrder + 1;
            }
        }
        else {
            //get the removed option
            var removedOption_1 = selectedSector.filter(function (x) { return !item.includes(x); }).concat(item.filter(function (x) { return !selectedSector.includes(x); }));
            setSelectedSector(item);
            setRows(function (prevRows) {
                return prevRows.filter(function (item) {
                    return !(item.field === "code_scian_primaire.code" &&
                        item.value === removedOption_1[0].value);
                });
            });
        }
    };
    //handle Secondary sectore change
    var handleSecondarySectore = function (item) {
        setPage(0);
        //get latest selected
        var latestSelected = selectedSecSector
            .filter(function (x) { return !item.includes(x); })
            .concat(item.filter(function (x) { return !selectedSecSector.includes(x); }));
        var newSecondarySector = {
            order: 99999999,
            condition: "or",
            field: "code_scian_secondaire.code",
            operator: "istartswith",
            value: latestSelected[0].value,
        };
        if (item.length > selectedSecSector.length) {
            setSelectedSecSector(item);
            setRows(function (prevStat) {
                return __spreadArray(__spreadArray([], __read(prevStat), false), [newSecondarySector], false);
            });
            if (rows.some(function (obj) {
                return Object.values(obj).includes("code_scian_secondaire.code");
            })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === "code_scian_secondaire.code"; });
                newSecondarySector.order = rows[indx].order;
            }
            else {
                var bigOrder = getHighestOrder(rows);
                newSecondarySector.order = bigOrder + 1;
            }
        }
        else {
            //get the removed option
            var removedOption_2 = selectedSecSector
                .filter(function (x) { return !item.includes(x); })
                .concat(item.filter(function (x) { return !selectedSecSector.includes(x); }));
            setSelectedSecSector(item);
            setRows(function (prevRows) {
                return prevRows.filter(function (item) {
                    return !(item.field === "code_scian_secondaire.code" &&
                        item.value === removedOption_2[0].value);
                });
            });
        }
    };
    // Handle SCIAN change
    var handleScianChange = function (item) {
        handleSelectedSectore(item);
        handleSecondarySectore(item);
    };
    var handleFieldChange = function (item, field, setSelectedField, selectedField, rows, setRows) {
        setPage(0);
        // Get the latest selected
        var latestSelected = selectedField
            .filter(function (x) { return !item.includes(x); })
            .concat(item.filter(function (x) { return !selectedField.includes(x); }));
        var newField = {
            order: 99999999,
            condition: "or",
            field: field,
            operator: "is",
            value: latestSelected[0].value,
        };
        if (item.length > selectedField.length) {
            setSelectedField(item);
            if (rows.some(function (obj) { return Object.values(obj).includes(field); })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === field; });
                newField.order = rows[indx].order;
            }
            else {
                var bigOrder = getHighestOrder(rows);
                newField.order = bigOrder + 1;
            }
            if (typeof newField.value === 'object') {
                setRows(function (prevStat) {
                    return __spreadArray(__spreadArray([], __read(prevStat), false), __read(newField.value.map(function (value) {
                        return __assign(__assign({}, newField), { value: value });
                    })), false);
                });
            }
            else {
                setRows(function (prevRows) {
                    return __spreadArray(__spreadArray([], __read(prevRows), false), [newField], false);
                });
            }
        }
        else {
            // Get the removed option
            var removedOption_3 = selectedField
                .filter(function (x) { return !item.includes(x); })
                .concat(item.filter(function (x) { return !selectedField.includes(x); }));
            setSelectedField(item);
            if (typeof removedOption_3[0].value === 'object') {
                setRows(function (prevRows) {
                    return prevRows.filter(function (item) { return !(item.field === field && removedOption_3[0].value.includes(item.value)); });
                });
            }
            else {
                setRows(function (prevRows) {
                    return prevRows.filter(function (item) { return !(item.field === field && item.value === removedOption_3[0].value); });
                });
            }
        }
    };
    var handleMrcChange = function (item) {
        handleFieldChange(item, "adresses.mrc.id", setSelectedMrc, selectedMrc, rows, setRows);
    };
    var handleNorthMrcChange = function (item) {
        handleFieldChange(item, "adresses.mrc.id", setSelectedMrcNorth, selectedMrcNorth, rows, setRows);
        handleFieldChange(item, "autres_address.mrc.id", setSelectedMrcNorth, selectedMrcNorth, rows, setRows);
    };
    var handleRegionChange = function (item) {
        handleFieldChange(item, "adresses.region_adminsitrative.id", setSelectedRegion, selectedRegion, rows, setRows);
    };
    var handleNorthRegionChange = function (item) {
        handleFieldChange(item, "adresses.region_adminsitrative.id", setSelectedRegionNorth, selectedRegionNorth, rows, setRows);
        handleFieldChange(item, "autres_address.region_adminsitrative.id", setSelectedRegionNorth, selectedRegionNorth, rows, setRows);
    };
    //Handle type membre change
    var handleTypeMembreChnage = function (e) {
        setPage(0);
        var newMemberType = {
            order: 99999999,
            condition: "or",
            field: "type_company",
            operator: "is",
            value: e.value,
        };
        if (rows.findIndex(function (row) { return row.field === "type_company"; }) == -1) {
            setRows(function (prevStat) { return __spreadArray(__spreadArray([], __read(prevStat), false), [newMemberType], false); });
        }
        else {
            var rowsCopy = __spreadArray([], __read(rows), false);
            var rowIndex = rowsCopy.findIndex(function (row) { return row.field === "type_company"; });
            if (rowIndex !== -1) {
                rowsCopy[rowIndex] = __assign(__assign({}, rowsCopy[rowIndex]), { value: newMemberType.value });
                setRows(rowsCopy);
            }
        }
        setTypeMembre([newMemberType]);
        if (rows.some(function (obj) { return Object.values(obj).includes("type_company"); })) {
            var indx = rows.findIndex(function (obj) { return obj["field"] === "type_company"; });
            newMemberType.order = rows[indx].order;
        }
        else {
            var bigOrder = getHighestOrder(rows);
            newMemberType.order = bigOrder + 1;
        }
    };
    // Handle Is member Change
    var handleIsMemberChange = function (e) {
        setPage(0);
        var newIsMember = {
            order: 99999999,
            condition: "or",
            field: "is_member",
            operator: "is",
            value: e.value,
            label: e.label,
        };
        if (rows.findIndex(function (row) { return row.field === "is_member"; }) == -1) {
            setRows(function (prevStat) { return __spreadArray(__spreadArray([], __read(prevStat), false), [newIsMember], false); });
        }
        else {
            var rowsCopy = __spreadArray([], __read(rows), false);
            var rowIndex = rowsCopy.findIndex(function (row) { return row.field === "is_member"; });
            if (rowIndex !== -1) {
                rowsCopy[rowIndex] = __assign(__assign({}, rowsCopy[rowIndex]), { value: newIsMember.value });
                setRows(rowsCopy);
            }
        }
        setIsMember([newIsMember]);
        if (rows.some(function (obj) { return Object.values(obj).includes("is_member"); })) {
            var indx = rows.findIndex(function (obj) { return obj["field"] === "is_member"; });
            newIsMember.order = rows[indx].order;
        }
        else {
            var bigOrder = getHighestOrder(rows);
            newIsMember.order = bigOrder + 1;
        }
    };
    var handleSearchInput = function (e, tab) {
        if (tab === void 0) { tab = 3; }
        if (tab != 3)
            setAdvSearch(true);
        setSearchInput(e.target.value);
    };
    //search Companies
    var searchCompanies = function () {
        setPage(0);
        setSearchedTerm(searchInput);
    };
    // handleKeyDown function
    var handleKeyDown = function (event) {
        if (event.key === "Enter") {
            setPage(0);
            setSearchedTerm(searchInput);
        }
    };
    var handleOnchangeCompanyCategory = function (item) {
        setPage(0);
        //get latest selected
        var latestSelected = selectedcompanyCategories
            .filter(function (x) { return !item.includes(x); })
            .concat(item.filter(function (x) { return !selectedcompanyCategories.includes(x); }));
        var newCat = {
            order: 99999999,
            condition: "or",
            field: "categorie_entreprise.id",
            operator: "is",
            value: latestSelected[0].value,
        };
        if (item.length > selectedcompanyCategories.length) {
            setSelectedCompanyCategories(item);
            setRows(function (prevStat) {
                return __spreadArray(__spreadArray([], __read(prevStat), false), [newCat], false);
            });
            if (rows.some(function (obj) {
                return Object.values(obj).includes("categorie_entreprise.id");
            })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === "categorie_entreprise.id"; });
                newCat.order = rows[indx].order;
            }
            else {
                var bigOrder = getHighestOrder(rows);
                newCat.order = bigOrder + 1;
            }
        }
        else {
            //get the removed option
            var removedOption_4 = selectedcompanyCategories.filter(function (x) { return !item.includes(x); }).concat(item.filter(function (x) { return !selectedcompanyCategories.includes(x); }));
            setSelectedCompanyCategories(item);
            setRows(function (prevRows) {
                return prevRows.filter(function (item) {
                    return !(item.field === "categorie_entreprise.id" &&
                        item.value === removedOption_4[0].value);
                });
            });
        }
    };
    var getTitle = function (langCode, dataFilterTitle) {
        if (dataFilterTitle === 'fournisseurs' || dataFilterTitle === 'providers') {
            return langCode === 'fr' ? 'fournisseurs' : 'suppliers';
        }
        else if (dataFilterTitle === 'intervenants' || dataFilterTitle === 'stakeholders') {
            return langCode === 'fr' ? 'intervenants' : 'stakeholders';
        }
        else if (dataFilterTitle === 'contracteurs' || dataFilterTitle === 'contractors') {
            return langCode === 'fr' ? "donneurs d'ordres" : 'contractors';
        }
        return '';
    };
    // View functions
    var handleView = function () {
        if (view === "carte") {
            setView("list");
        }
        else {
            setView("carte");
        }
    };
    //reset filters
    var resetFilters = function () {
        setSelectedMrc([]);
        setSelectedRegion([]);
        setSelectedSecSector([]);
        setSelectedSector([]);
        setSelectedCompanyCategories([]);
        setSelectedMrcNorth([]);
        setRows([
            {
                order: 100000001,
                condition: "and",
                field: "is_member",
                operator: "is",
                value: true,
                label: "Oui",
            },
            {
                order: 100000002,
                condition: "and",
                field: "status",
                operator: "is",
                value: ["actived", "imported"],
            }
        ]);
    };
    return (_jsx(MembersContext.Provider, __assign({ value: {
            searchInput: searchInput,
            view: view,
            selectedSector: selectedSector,
            typeMembre: typeMembre,
            companiesIsLoading: companiesIsLoading,
            filtersIsLoading: filtersIsLoading,
            pageCount: pageCount,
            handlePageClick: handlePageClick,
            companies: companies,
            sectores: sectores,
            secSectors: secSectors,
            rows: rows,
            selectedSecSector: selectedSecSector,
            companyType: companyType,
            isMemberValues: isMemberValues,
            count: count,
            setSecSectors: setSecSectors,
            handleSearchInput: handleSearchInput,
            setSearchedTerm: setSearchedTerm,
            searchCompanies: searchCompanies,
            handleKeyDown: handleKeyDown,
            handleView: handleView,
            handleSelectedSectore: handleSelectedSectore,
            handleTypeMembreChnage: handleTypeMembreChnage,
            isMember: isMember,
            handleIsMemberChange: handleIsMemberChange,
            setCompanies: setCompanies,
            handleSecondarySectore: handleSecondarySectore,
            handleScianChange: handleScianChange,
            selectedMrc: selectedMrc,
            selectedMrcNorth: selectedMrcNorth,
            handleMrcChange: handleMrcChange,
            handleNorthMrcChange: handleNorthMrcChange,
            mrc: mrc,
            mrcNorth: mrcNorth,
            handleRegionChange: handleRegionChange,
            handleNorthRegionChange: handleNorthRegionChange,
            region: region,
            regionNorth: regionNorth,
            selectedRegion: selectedRegion,
            resetFilters: resetFilters,
            page: page,
            rowsSearchData: rowsSearchData,
            setAdvSearch: setAdvSearch,
            setRandQuery: setRandQuery,
            setRegion: setRegion,
            setRegionNorth: setRegionNorth,
            setMrc: setMrc,
            setMrcNorth: setMrcNorth,
            handleOnchangeCompanyCategory: handleOnchangeCompanyCategory,
            selectedcompanyCategories: selectedcompanyCategories,
            getTitle: getTitle,
        } }, { children: children })));
};
export default MembersContext;
